// index.js

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import authReducer from "./Store/auth";
import ScreenSizeWatcher from "./Pages/ScreenSizeWatcher";

const rootElement = document.getElementById("root");

ReactDOM.render(
  <Provider store={authReducer}>
    <ScreenSizeWatcher>
      {(isSmallScreen) => (isSmallScreen ? null : <App />)}
    </ScreenSizeWatcher>
  </Provider>,
  rootElement
);

reportWebVitals();
