import React, { useState, useEffect } from "react";
import style from "./index.module.css";
import ModalUSer from "./ModalUSer";
import ModalServicChild from "./ModalServicChild";
import styled from "styled-components";
import { NavLink, useParams } from "react-router-dom";
import UsePrivate from "../../../../Services/useAxiosPrivate2";
import Modal from "../../../../Components/Modal/Modal";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { changeToast } from "../../../../Store/auth";
import { useDispatch, useSelector } from "react-redux";
import { RotatingLines } from "react-loader-spinner";
import Selectnew from "../../../../Components/Select/Selectnew";
import ModalSelect from "../../../../Components/Modal/ModalSelect";
const ModalContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 60px 30px 20px 30px;
`;
const containerVariant = {
  initial: { right: "-50%", transition: { type: "spring" } },
  isOpen: { right: "-15%" },
  exit: { right: "-50%" },
};
const initial = {
  client: {
    clientClientTypeId: 1,
    clientVoenorPassportNo: "",
    clientFullName: "",
  },
  sale: {
    saleDeclarationNo: "",
    saleComment: "",
    saleIsDiplomatic: false,
    saleCarNumber: "",
  },
  saleDetails: [],
  freeFromMain: false,
  freeFromVat: false,
};
const ErrorInitial = {
  client: {
    ClientFullName: null,
    ClientVoenorPassportNo: null,
  },
};

function NewService() {
  const val = useParams();
  const { userFullName, userPrecinct, Notifigation } = useSelector(
    state => state.auth
  );
  const [open, setOpen] = useState(false);
  const [valid, setValid] = useState(ErrorInitial);
  const dispatch = useDispatch();
  const { sendRequest } = UsePrivate();
  const navigate = useNavigate();
  const [db, setDb] = useState();
  const [isOpen, toggle] = useState(false);
  const [isServiceOpen, toggleService] = useState(false);
  const [isServiceChildOpen, toggleServiceChild] = useState(null);
  const [mobValue, setmobmodal] = useState();
  const [customtype, setCustomtype] = useState(1);
  const [formdata, setFormData] = useState(initial);
  const [saledata, setSaledata] = useState([]);
  const [load, setLoad] = useState(false);
  function printDefault() {
    if (formdata.saleDetails.length > 0) {
      var a = `<!DOCTYPE html>
      <html lang="en">
      <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Document</title>
          <style>
            *{
                  margin: 0;
                  padding: 0;
                  visibility:hidden;
                  background-color: black;
                  box-sizing:border-box;
              }
              table,td, th{
                  text-align:center;
                  border: 1px solid;
                  border-collapse: collapse;
                  padding: 1.5px 3px;
              }
              table{
                width:100%;
              }
              @media print{
                  *{
                    visibility:visible;
                  }
              }
              .flex{
                display: flex;
                justify-content: space-between;
                margin: 2em 0;
              }
              .flex div:nth-child(2)
              {
                  margin-right: 10%;
              }
          </style>
      </head>
      <body>
      <h4 style="width:80%;text-align:center;margin:1em 10%;">Azərbaycan Respublikası Dövlət Gömrük Komitəsi <br>
      "Azərterminalkompleks" Birliyi</h4>
      <div class="flex">
        <div>
          <p>Ödəyici:${formdata.client.clientFullName}</p>
          <p>VÖEN:${formdata.client.clientVoenorPassportNo}</p>
        </div>
        <div>
          <p>Hesab № _______________</p>
          <p>Ödəniş tarixi </p>
        </div>
      </div>
      <small>Ünvan</small>
      <table>
          <thead>
            <tr>
              <th>Xidmətin adı</th>
              <th>Say</th>
              <th>Xidmət haqqı</th>
              <th>CƏM</th>
            </tr>
          </thead>
          <tbody>`;
      var totalQuantity = 0,
        totalPrice = 0,
        totalAmount = 0;
      formdata.saleDetails.forEach(x => {
        totalQuantity += x.saleDetailQuantity;
        totalPrice += x.saleDetailPrice;
        totalAmount += x.saleDetailAmount;
        a += `
        <tr>
          <td>${x.saleDetailName}</td>
          <td>${x.saleDetailQuantity}</td>
          <td>${x.saleDetailPrice}</td>
          <td>${x.saleDetailAmount}</td>
        </tr>`;
      });

      a += `
        <tr>
          <td>Cəmi</td>
          <td>${totalQuantity}</td>
          <td>${totalPrice}</td>
          <td>${totalAmount}</td>
        </tr>
        </tbody>
        </table>
          <p>  GB və TXG üzrə ${userPrecinct} şöbəsi</p>
          <p style="text-decoration: underline">${userFullName}</p>
          <div class="flex print-text">
            <div>
                <p>Azərbaycan Respublikası Dövlət Gömrük Komitəsinin </p>
                <p>“Azərterminalkompleks” Birliyi</p>
                <p>VÖEN : 1802077241</p>
                <p>Hesab nömrəsi : AZ87PAHA38090AZNHC0100070889</p>
                <p>İdentifikasiya nömrəsi : 000708889</p>
                <p>“PAŞA Bank ASC” </p>
                <p>Kod : 505141</p>
                <p>VÖEN : 170767721</p>
                <p>S.W.I.F.T : PAHAAZ22</p>
                <p>Müxbir hesab : AZ82NABZ01350100000000071944</p>
            </div>
            <div>
                <p>Azərbaycan Respublikası Dövlət Gömrük Komitəsinin</p>
                <p>“Azərterminalkompleks” Birliyi  (ƏDV)</p>
                <p>VÖEN : 1802077241</p>
                <p>Dövlət Xəzinədarlıq Agentliyi (ƏDV)</p>
                <p>Kod : 69001</p>
                <p>VÖEN : 1401555071</p>
                <p>Müxbir hesab : AZ07NABZ01360100000000004944</p>
                <p>S.W.I.F.T BIK : CTREAZ24</p>
                <p>Hesab  № : AZ48CTRE00000000000000014001</p>
            </div>
          </div>
        </body>
      </html>`;
      var WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );
      WinPrint.document.write(a);
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    } else {
      alert("Məlumat daxil edilməyib");
    }
    // console.log(a);
  }

  const noPrint = true;
  useEffect(() => {
    const handleKeyDown = e => {
      if (e.ctrlKey && e.key === "p") {
        if (noPrint) {
          e.preventDefault();
          e.stopPropagation();
          if (formdata.saleDetails.length > 0) {
            printDefault();
          }
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (val !== null) {
      setFormData({
        ...formdata,
        ["client"]: {
          ...formdata["client"],
          ["clientVoenorPassportNo"]: val.voen,
        },
      });
    }
    const fetchData = async () => {
      try {
        const res = await sendRequest("GET", `Inspector/NewSale`, null);
        if (res.isSuccess) {
          setDb(res.result);
        } else {
          dispatch(
            changeToast({ loading: false, type: false, message: res.message })
          );
        }
      } catch (err) {
        console.error(err);
        dispatch(
          changeToast({ loading: false, type: false, message: err.message })
        );
      }
    };
    fetchData();
  }, []);
  function handlOpenModal(open) {
    toggle(open);
  }
  function handlOpenModal2(open) {
    toggleService(open);
  }
  function handlOpenModal3(open) {
    toggleServiceChild(open);
  }

  const handleSaleDetailsChange = (service, count) => {
    const updatedSaleDetails = saledata.map(saleDetail => {
      if (saleDetail.saleDetailServiceId === service.serviceId) {
        // Update existing sale detail
        return {
          ...saleDetail,
          saleDetailQuantity: parseInt(count) || 0,
        };
      }
      return saleDetail;
    });
    if (
      !updatedSaleDetails.find(
        saleDetail => saleDetail.saleDetailServiceId === service.serviceId
      )
    ) {
      const newSaleDetail = {
        saleDetailServiceId: service.serviceId,
        saleDetailName: service.serviceName,
        saleDetailUnit: service.serviceUnit,
        saleDetailCode: service.serviceCode,
        saleDetailQuantity: parseInt(count) || 0,
        saleDetailPrice: service.servicePrice,
        saleDetailVatrate: 18,
      };
      updatedSaleDetails.push(newSaleDetail);
    }

    setSaledata(updatedSaleDetails);
  };
  const submitServiceDetail = () => {
    const updatedSaleDetails = [...formdata.saleDetails];
    saledata.forEach(newSaleDetail => {
      const index = updatedSaleDetails.findIndex(
        existingSaleDetail =>
          existingSaleDetail.saleDetailServiceId ===
          newSaleDetail.saleDetailServiceId
      );
      if (index !== -1) {
        updatedSaleDetails[index] = {
          ...updatedSaleDetails[index],
          ...newSaleDetail,
        };
      } else {
        updatedSaleDetails.push(newSaleDetail);
      }
    });
    setFormData({
      ...formdata,
      saleDetails: updatedSaleDetails.filter(s => s.saleDetailQuantity !== 0),
    });
    setSaledata([]);
    handlOpenModal3();
    toggleService(false);
  };

  function VoenSearch() {
    if (
      formdata.client.clientVoenorPassportNo !== undefined &&
      formdata.client.clientVoenorPassportNo !== ""
    ) {
      const fetchData = async () => {
        try {
          const res = await sendRequest(
            "Get",
            `Inspector/GetVOEN/${formdata.client.clientVoenorPassportNo}`,
            null
          );

          console.log(res);
          if (res.result !== null) {
            dispatch(
              changeToast({ loading: false, type: true, message: "Uğurlu" })
            );
            setFormData({
              ...formdata,
              ["client"]: {
                ...formdata["client"],
                ["clientFullName"]: res.result,
              },
            });
          } else {
            dispatch(
              changeToast({
                loading: false,
                type: false,
                message: "Belə VÖEN yoxdur",
              })
            );
          }
        } catch (err) {
          console.error(err);
          dispatch(
            changeToast({ loading: false, type: false, message: err.message })
          );
        }
      };
      fetchData();
    } else {
      dispatch(
        changeToast({
          loading: false,
          type: false,
          message: "VÖEN boş ola bilməz",
        })
      );
    }
  }

  const handleChange = e => {
    const { name, value } = e;
    if (e) {
      setValid(ErrorInitial);
      if (name.includes(".")) {
        const [parent, child] = name.split(".");

        setFormData({
          ...formdata,
          [parent]: {
            ...formdata[parent],
            [child]: value,
          },
        });
      } else {
        setFormData({ ...formdata, [name]: value });
      }
    }
  };

  const checkadv = ({ name, value }) => {
    setFormData({ ...formdata, [name]: value });
  };

  useEffect(() => {
    if (val.voen !== undefined) {
      const fetchData = async () => {
        try {
          const res = await sendRequest(
            "Get",
            `Inspector/GetVOEN/${val.voen}`,
            null
          );

          if (res.result !== null) {
            dispatch(
              changeToast({ loading: false, type: true, message: "Uğurlu" })
            );
            setFormData({
              ...formdata,
              ["client"]: {
                ...formdata["client"],
                ["clientFullName"]: res.result,
                ["clientVoenorPassportNo"]: val.voen,
              },
            });
          } else {
            dispatch(
              changeToast({
                loading: false,
                type: false,
                message: "Bele voen yoxdur",
              })
            );
          }
        } catch (err) {
          console.error(err);
          dispatch(
            changeToast({ loading: false, type: false, message: err.message })
          );
        }
      };
      fetchData();
    }
  }, []);

  const HandleSendFrom = async () => {
    dispatch(changeToast({ loading: true, type: false, message: null }));
    setLoad(true);
    if (formdata.saleDetails.length > 0) {
      try {
        const res = await sendRequest("Post", `Inspector/AddSale`, {
          client: {
            clientClientTypeId: customtype,
            clientVoenorPassportNo: formdata.client.clientVoenorPassportNo,
            clientFullName: formdata.client.clientFullName,
          },
          sale: {
            saleDeclarationNo: formdata.sale.saleDeclarationNo,
            salePrecinctId: formdata.sale.salePrecinctId,
            saleInspectorId: formdata.sale.saleInspectorId,
            saleComment: formdata.sale.saleComment,
            SalePayStatusId: formdata.sale.SalePayStatusId,
            saleIsDiplomatic: formdata.sale.saleIsDiplomatic == "true",
            saleCarNumber: formdata.sale.saleCarNumber,
          },
          saleDetails: formdata.saleDetails.map(detail => ({
            saleDetailServiceId: detail.saleDetailServiceId,
            saleDetailQuantity: detail.saleDetailQuantity,
            saleDetailPrice: detail.saleDetailPrice,
          })),
          freeFromMain: formdata.freeFromMain,
          freeFromVat: formdata.freeFromVat,
        });
        if (res.isSuccess) {
          dispatch(
            changeToast({
              loading: false,
              type: true,
              message: "Uğurla əlavə edildi",
            })
          );
          navigate("/Services");
        } else {
          dispatch(
            changeToast({ loading: false, type: false, message: res.message })
          );
        }
      } catch (err) {
        console.log(err);
        if (err?.response?.status === 400) {
          setValid({
            client: {
              ClientFullName: err.response.data?.errors["client.ClientFullName"]
                ? err.response.data.errors["client.ClientFullName"][0]
                : "",
              ClientVoenorPassportNo: err.response.data.errors[
                "client.ClientVoenorPassportNo"
              ]
                ? err.response.data.errors["client.ClientVoenorPassportNo"][0]
                : "",
            },
            // sale: {
            //   SaleCarNumber: err.response.data.errors["sale.SaleCarNumber"][0]
            //     ? err.response.data.errors["sale.SaleCarNumber"][0]
            //     : "",
            // },
          });
          dispatch(
            changeToast({
              loading: false,
              type: false,
              message: "Validation error!!!",
            })
          );
        } else {
          dispatch(
            changeToast({
              loading: false,
              type: false,
              message: err.message,
            })
          );
        }
      }
      setLoad(false);
    } else {
      dispatch(
        changeToast({
          loading: false,
          type: false,
          message: "Satış daxil etmelisiz",
        })
      );
      setLoad(false);
    }
  };
  const PassPortSearch = () => {
    if (
      formdata?.client.clientVoenorPassportNo !== undefined &&
      formdata?.client.clientVoenorPassportNo !== ""
    ) {
      const fetchData = async () => {
        try {
          const res = await sendRequest(
            "Get",
            `Inspector/GetPassportNo/${formdata?.client.clientVoenorPassportNo}`,
            null
          );

          console.log(res);
          if (res.isSuccess) {
            dispatch(
              changeToast({ loading: false, type: true, message: "Uğurlu" })
            );
            setFormData({
              ...formdata,
              ["client"]: {
                ...formdata["client"],
                ["clientFullName"]: res.result.fullName,
              },
            });
            // ["clientVoenorPassportNo"]: res.result.voen,
          } else {
            dispatch(
              changeToast({
                loading: false,
                type: false,
                message: "Passport nömrəsi yanlışdır",
              })
            );
          }
        } catch (err) {
          console.error(err);
          dispatch(
            changeToast({ loading: false, type: false, message: err.message })
          );
        }
      };
      fetchData();
    } else {
      dispatch(
        changeToast({
          loading: false,
          type: false,
          message: "Passport boş ola bilməz",
        })
      );
    }
  };
  const handleSearch = e => {
    dispatch(changeToast({ loading: true, type: false, message: null }));
    if (
      formdata?.sale.saleDeclarationNo !== undefined &&
      formdata?.sale.saleDeclarationNo !== ""
    ) {
      console.log(formdata.saleDeclarationNo);
      const fetchData = async () => {
        try {
          const res = await sendRequest(
            "Get",
            `Inspector/GetVOENFromDeclarationNo/${formdata?.sale.saleDeclarationNo}`,
            null
          );

          console.log(res);
          if (res.isSuccess) {
            dispatch(
              changeToast({ loading: false, type: true, message: "Uğurlu" })
            );
            setFormData({
              ...formdata,
              ["client"]: {
                ...formdata["client"],
                ["clientFullName"]: res.result.fullName,
                ["clientVoenorPassportNo"]: res.result.voen,
              },
            });
          } else {
            dispatch(
              changeToast({
                loading: false,
                type: false,
                message: "Bəyannamə nömrəsi yanlışdır",
              })
            );
          }
        } catch (err) {
          console.error(err);
          dispatch(
            changeToast({ loading: false, type: false, message: err.message })
          );
        }
      };
      fetchData();
    } else {
      dispatch(
        changeToast({
          loading: false,
          type: false,
          message: "Bəyannamə  boş ola bilməz",
        })
      );
    }
  };
  const handlechangeval = (id, text, voen) => {
    console.log(id);
    console.log(text);
    console.log(voen);
    setFormData({
      ...formdata,
      ["client"]: {
        ...formdata["client"],
        ["clientFullName"]: text,
        ["clientVoenorPassportNo"]: voen,
      },
    });

    setOpen(false);
  };

  const handleKeyPress = (e, searchFunction) => {
    if (e.key === "Enter") {
      e.preventDefault();
      searchFunction();
    }
  };

  return (
    <div className={style.container}>
      <div className={style.Head}>
        <div className={style.HeadMain}>
          <div className={style.HeadTitle}>
            <h3>Xidmətlərin göstərilməsi</h3>
          </div>
          <ul className={style.HeadBody}>
            <li>
              <button
                className={style.HeadAddButton}
                onClick={HandleSendFrom}
                disabled={load}
              >
                <img src="/icons/save 1.svg" alt="" />
                <span>
                  {load ? (
                    <RotatingLines
                      visible={true}
                      height="15"
                      width="15"
                      strokeColor="white"
                      // color="white"
                      // strokeWidth="5"
                      animationDuration="0.75"
                      ariaLabel="rotating-lines-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  ) : (
                    "Yadda saxla"
                  )}
                </span>
              </button>
            </li>
            <li>
              <NavLink to={"/Services"}>
                <button className={style.HeadPrintButton}>
                  <img src="/icons/x-circle 1.svg" alt="" />
                  <span>Ləğv et</span>
                </button>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
      <div className={style.Body}>
        <div className={style.ClientTypes}>
          <label htmlFor="customerType1">
            <input
              id="customerType1"
              placeholder="Tarix"
              type="radio"
              name="client.clientClientTypeId"
              onChange={e => {
                setCustomtype(1);
                handleChange(e.target);
                setFormData({
                  ...formdata,
                  ["client"]: {
                    ...formdata["client"],
                    ["clientFullName"]: "",
                    ["clientVoenorPassportNo"]: "",
                  },
                  ["sale"]: {
                    ...formdata["sale"],
                    ["saleDeclarationNo"]: "",
                  },
                });
              }}
              value={1}
              defaultChecked={formdata.client.clientClientTypeId == 1}
            />
            Sahibkar
          </label>
          <label htmlFor="customerType2">
            <input
              id="customerType2"
              placeholder="Tarix"
              type="radio"
              value={2}
              name="client.clientClientTypeId"
              onChange={e => {
                setCustomtype(2);
                handleChange(e.target);
                setFormData({
                  ...formdata,
                  ["client"]: {
                    ...formdata["client"],
                    ["clientFullName"]: "",
                    ["clientVoenorPassportNo"]: "",
                  },
                  ["sale"]: {
                    ...formdata["sale"],
                    ["saleDeclarationNo"]: "",
                  },
                });
              }}
            />
            Vətəndaş
          </label>

          <label htmlFor="customerType3">
            <input
              id="customerType3"
              placeholder="Tarix"
              type="radio"
              value={3}
              name="client.clientClientTypeId"
              onChange={e => {
                setCustomtype(3);
                handleChange(e.target);
                setFormData({
                  ...formdata,
                  ["client"]: {
                    ...formdata["client"],
                    ["clientFullName"]: "",
                    ["clientVoenorPassportNo"]: "",
                  },
                  ["sale"]: {
                    ...formdata["sale"],
                    ["saleDeclarationNo"]: "",
                  },
                });
              }}
            />
            Qeyri-rezident
          </label>
          <label htmlFor="Ödənişdən">
            <input
              id="Ödənişdən"
              type="checkbox"
              name="freeFromMain"
              value={!formdata.freeFromMain}
              onChange={e =>
                checkadv({
                  name: "freeFromMain",
                  value: !formdata.freeFromMain,
                })
              }
            />
            Ödənişdən azad
          </label>
          <label htmlFor="ƏDV-dən">
            <input
              id="ƏDV-dən"
              type="checkbox"
              name="freeFromVat"
              value={!formdata.freeFromVat}
              onChange={e =>
                checkadv({ name: "freeFromVat", value: !formdata.freeFromVat })
              }
            />
            ƏDV-dən azad
          </label>
        </div>
        <div className={style.BodySearch}>
          <ul className={customtype ? style.FormItems : style.formNone}>
            <li
              className={
                customtype === 1 || customtype === 2
                  ? style.FormItem
                  : style.formNone
              }
            >
              <label>Bəyannamə</label>
              <div className={style.searchDiv}>
                <input
                  type="text"
                  placeholder="Bəyannamə..."
                  name="sale.saleDeclarationNo"
                  className={style.searchinput}
                  value={
                    formdata?.sale.saleDeclarationNo
                      ? formdata?.sale.saleDeclarationNo
                      : ""
                  }
                  onChange={e => handleChange(e.target)}
                  onKeyPress={e => handleKeyPress(e, () => handleSearch(1))}
                />
                <button onClick={() => handleSearch(1)}>
                  <img src="/icons/Vectorloop.svg" alt="" />
                </button>
              </div>
            </li>
            <li className={customtype === 1 ? style.FormItem : style.formNone}>
              <label>VÖEN</label>
              <div className={style.searchDiv}>
                <input
                  type="text"
                  placeholder="VÖEN..."
                  name="client.clientVoenorPassportNo"
                  className={style.searchinput}
                  style={
                    valid.client.ClientVoenorPassportNo
                      ? { border: "1px solid red" }
                      : {}
                  }
                  value={
                    formdata?.client?.clientVoenorPassportNo
                      ? formdata.client.clientVoenorPassportNo
                      : ""
                  }
                  onChange={e => handleChange(e.target)}
                  onKeyPress={e => handleKeyPress(e, VoenSearch)}
                />
                <button onClick={VoenSearch}>
                  <img src="/icons/Vectorloop.svg" alt="" />
                </button>
              </div>
            </li>
            <li
              className={
                customtype === 3 || customtype === 2
                  ? style.FormItem
                  : style.formNone
              }
            >
              <label>Passport nömrəsi</label>
              <div className={style.searchDiv}>
                <input
                  type="text"
                  placeholder="Passport..."
                  name="client.clientVoenorPassportNo"
                  className={style.searchinput}
                  style={
                    valid.client.ClientVoenorPassportNo
                      ? { border: "1px solid red" }
                      : {}
                  }
                  value={
                    formdata?.client.clientVoenorPassportNo
                      ? formdata?.client.clientVoenorPassportNo
                      : ""
                  }
                  onChange={e => handleChange(e.target)}
                />
                <button onClick={e => PassPortSearch()}>
                  <img src="/icons/Vectorloop.svg" alt="" />
                </button>
              </div>
            </li>
          </ul>
        </div>
        <form className={style.BodyForm}>
          <ul className={customtype ? style.FormItems : style.formNone}>
            <li className={`${style.FormItem} ${style.FormItemLong}`}>
              <label>
                Müştəri adı
                <input
                  type="text"
                  placeholder="Tam ad..."
                  name="client.clientFullName"
                  onChange={e => handleChange(e.target)}
                  style={
                    valid.client.ClientFullName
                      ? { border: "1px solid red" }
                      : {}
                  }
                  value={
                    formdata?.client.clientFullName
                      ? formdata?.client.clientFullName
                      : ""
                  }
                />
              </label>
              <span className={style.MNameSelect} onClick={e => setOpen(true)}>
                <img src="/icons/Vectorloop.svg" alt="" />
              </span>
            </li>
            <li className={`${style.FormItem} ${style.FormItemDqn}`}>
              <label>DQN</label>
              <input
                type="text"
                placeholder="DQN..."
                name="sale.saleCarNumber"
                value={
                  formdata?.sale.saleCarNumber
                    ? formdata?.sale.saleCarNumber
                    : ""
                }
                onChange={e => handleChange(e.target)}
              />
            </li>
            <li className={`${style.FormItem} ${style.FormButtonItem}`}></li>
            <li className={`${style.FormItem} ${style.FormButtonItem}`}>
              <button
                type="button"
                className={style.AddService}
                onClick={e => {
                  handlOpenModal2(true);
                }}
              >
                <img src="/icons/pluscircle.svg" alt="" />
                <span>Xidmət əlavə et</span>
              </button>
            </li>
          </ul>
        </form>
        <div className={style.BodyInfoForm}>
          <table className={style.NewSericeTable}>
            <thead className={style.TableHeada}>
              <tr>
                <th>No:</th>
                <th>Xidmətin adı</th>
                <th>Ölçü vahidi</th>
                <th>Miqdarı</th>
                <th>Qiyməti</th>
                <th>Məbləğ</th>
                <th>ƏDV dərəcəsi</th>
                <th>ƏDV məbləği</th>
                <th>Cəmi məbləğ</th>
              </tr>
            </thead>
            <tbody className={style.TableBody}>
              {formdata.saleDetails.map((x, i) => {
                return (
                  <tr key={x.saleDetailServiceId}>
                    <td>{i + 1}</td>
                    <td>{x.saleDetailName}</td>
                    <td>{x.saleDetailUnit}</td>
                    <td>{x.saleDetailQuantity}</td>
                    <td>
                      <div>{Number(x.saleDetailPrice).toFixed(2)}</div>
                    </td>
                    <td>
                      {Number(x.saleDetailQuantity * x.saleDetailPrice).toFixed(
                        2
                      )}
                    </td>
                    <td>
                      {formdata?.freeFromVat ? "0" : x.saleDetailVatrate}%
                    </td>
                    <td>
                      {formdata?.freeFromVat
                        ? "0.00"
                        : Number(
                            (x.saleDetailQuantity * x.saleDetailPrice * 18) /
                              100
                          ).toFixed(2)}
                    </td>
                    <td>
                      {formdata?.freeFromMain && formdata.freeFromVat
                        ? "0.00"
                        : formdata.freeFromVat
                        ? Number(
                            x.saleDetailQuantity * x.saleDetailPrice
                          ).toFixed(2)
                        : formdata?.freeFromMain
                        ? Number(
                            (x.saleDetailQuantity * x.saleDetailPrice * 18) /
                              100
                          ).toFixed(2)
                        : Number(
                            x.saleDetailQuantity * x.saleDetailPrice +
                              (x.saleDetailQuantity * x.saleDetailPrice * 18) /
                                100
                          ).toFixed(2)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className={style.BodyUserNot}>
          <div className={style.FormItem}>
            <label>Qeyd</label>
            <input
              style={{
                width: "100% !important",
                background: "F6F6F6",
                padding: "10px 15px",
              }}
              name="sale.saleComment"
              onChange={handleChange}
              value={
                formdata.sale.saleComment ? formdata?.sale.saleComment : ""
              }
              type=""
              placeholder="Maksimum 400 simvol"
            />
          </div>
        </div>
      </div>

      <ModalServicChild
        isOpen={isServiceChildOpen}
        handleClose={() => (handlOpenModal3(null), setSaledata([]))}
      >
        <ModalContent>
          <ul className={style.ModalTitle}>
            <h1>{isServiceChildOpen?.groupName}</h1>
            <i
              className="fa-solid fa-xmark mainModalX"
              onClick={() => {
                // handlOpenModal2(false);
                handlOpenModal3(null);
              }}
            ></i>
          </ul>
          <ul style={{ padding: "20px" }}>
            {isServiceChildOpen?.services?.map(e => {
              return (
                <li
                  key={e.serviceId}
                  className={`${style.FormItem} ${style.childLi}`}
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    marginBottom: "5px",
                  }}
                >
                  {/* bunu at inpsctora */}
                  <label style={{ width: "80%" }}>{e.serviceName}</label>
                  <div className={style.MinusPlus}>
                    <button
                      className={style.MinusPlusButton}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        const existingSaleDetail = saledata.find(
                          s => s.saleDetailServiceId === e.serviceId
                        );
                        const drCount = formdata.saleDetails.find(
                          x => x.saleDetailServiceId === e.serviceId
                        );
                        const existingQuantity = existingSaleDetail
                          ? existingSaleDetail.saleDetailQuantity
                          : drCount
                          ? drCount.saleDetailQuantity
                          : 0;
                        const newQuantity = existingQuantity - 1;

                        handleSaleDetailsChange(
                          e,
                          newQuantity < 0 ? 0 : newQuantity
                        );
                      }}
                    >
                      <i className="fa-solid fa-minus"></i>
                    </button>
                    <input
                      id={`quantityInput_${e.serviceId}`}
                      min={0}
                      style={{ width: "40%", padding: "0 7px" }}
                      type="number"
                      value={
                        saledata.find(
                          s => s.saleDetailServiceId === e.serviceId
                        )
                          ? saledata.find(
                              x => x.saleDetailServiceId === e.serviceId
                            )?.saleDetailQuantity
                          : formdata.saleDetails.find(
                              x => x.saleDetailServiceId === e.serviceId
                            )?.saleDetailQuantity
                      }
                      onChange={x => {
                        handleSaleDetailsChange(e, x.target.value);
                      }}
                    />
                    <button
                      className={style.MinusPlusButton}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        const existingSaleDetail = saledata.find(
                          s => s.saleDetailServiceId === e.serviceId
                        );
                        const drCount = formdata.saleDetails.find(
                          x => x.saleDetailServiceId === e.serviceId
                        );
                        const existingQuantity = existingSaleDetail
                          ? existingSaleDetail.saleDetailQuantity
                          : drCount
                          ? drCount.saleDetailQuantity
                          : 0;
                        const newQuantity = existingQuantity + 1;

                        handleSaleDetailsChange(e, newQuantity);
                      }}
                    >
                      <i className="fa-solid fa-plus"></i>
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
          <ul
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "20px 0",
              justifyContent: "space-evenly",
              width: "100%",
            }}
          >
            <li style={{ listStyle: "none" }}>
              <button
                className={style.HeadAddButtonModal}
                onClick={submitServiceDetail}
              >
                <img src="/icons/save 1.svg" alt="" />
                <span>Yadda saxla</span>
              </button>
            </li>
            <li style={{ listStyle: "none" }}>
              <button
                className={style.HeadCancelButton}
                style={{ boxShadow: "0px 4px 17px 0px #8693A740" }}
                onClick={e => (handlOpenModal3(), setSaledata([]))}
              >
                <img src="/icons/x-circle 1.svg" alt="" />
                <span>Ləğv et</span>
              </button>
            </li>
          </ul>
        </ModalContent>
      </ModalServicChild>
      <Modal
        show={"none"}
        clshow={false}
        isOpen={isServiceOpen}
        handleClose={() => handlOpenModal2(false)}
        containerVariant={containerVariant}
        width={"30%"}
        height={"100%"}
        left={"top"}
        leftcount={"50%"}
        side={"right"}
        sidecount={"-15%"}
      >
        <ModalContent>
          <ul className={style.ModalTitle}>
            <h1>Xidmət Kataloqu</h1>
            <i
              className="fa-solid fa-xmark mainModalX"
              onClick={() => {
                handlOpenModal2(false);
                handlOpenModal3(null);
              }}
            ></i>
          </ul>
          <ul className={style.TableServiceBody}>
            {db &&
              db.map(x => {
                return (
                  <li
                    key={x.groupRuleGroupId}
                    onClick={e => {
                      handlOpenModal3(
                        db.find(s => s.groupRuleGroupId === x.groupRuleGroupId)
                      );
                    }}
                  >
                    <span>{x.groupName}</span>
                  </li>
                );
              })}
          </ul>
        </ModalContent>
      </Modal>
      <ModalSelect
        setOpen={setOpen}
        open={open}
        type={1}
        width={"100%"}
        height={"70%"}
        setVal={handleChange}
        funcval={true ? handlechangeval : null}
      />
    </div>
  );
}

export default NewService;
