import React, { useEffect, useState } from "react";
import style from "./index.module.css";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import styled from "styled-components";
import axios from "axios";
import Modal from "../../../../Components/Modal/Modal";
import usePrivate from "./../../../../Services/useAxiosPrivate2";
import { changeToast } from "../../../../Store/auth";
import { useDispatch, useSelector } from "react-redux";
import Select2Take from "../../../../Components/Select/SelectTake2";
import Loader from "../../../../Components/Loader/Loader";
const ModalContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    color: #5c3aff;
  }
`;
const containerVariant = {
  initial: { top: "-100%", transition: { type: "spring" } },
  isOpen: { top: "50%" },
  exit: { top: "-100%" },
};
const Paginations = ({
  status,
  name,
  voen,
  dqn,
  setLoad,
  pge = false,
  setpge,
}) => {
  const dispatch = useDispatch();
  const { sendRequest } = usePrivate();
  const [isOpen, toggle] = useState(false);
  const [result, setresult] = useState();
  const [service, setService] = useState(null);
  const [page, setPage] = useState(1);
  const [countpage, setCountpage] = useState(1);
  const { userFullName, userPrecinct } = useSelector(state => state.auth);
  const [take, setTake] = useState(25);
  const [totalAmountSum, setTotalAmountSum] = useState(0);
  const [totalVatAmountSum, setTotalVatAmountSum] = useState(0);
  const [totalTotalPrice, setTotalTotalPrice] = useState(0);

  const handlOpenModal = async (open, id) => {
    console.log("start get");
    setService(null);
    try {
      if (open) {
        const res = await sendRequest(
          "GET",
          `Inspector/SaleDetail/${id}`,
          null
        );
        if (res.isSuccess) {
          setService(res.result[0]);
          console.log(res.result[0]);
        } else {
          dispatch(
            changeToast({ loading: false, type: false, message: res.message })
          );
        }
      }
    } catch (err) {
      console.error(err);
      dispatch(
        changeToast({ loading: false, type: false, message: err.message })
      );
    }

    console.log("Finish get");

    toggle(open);
  };
  console.log(service);
  function printDefault() {
    if (service.saleDetails.length > 0) {
      var a = `<!DOCTYPE html>
      <html lang="en">
      <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Document</title>
          <style>
            *{
                  margin: 0;
                  padding: 0;
                  visibility:hidden;
                  background-color: black;
                  box-sizing:border-box;
              }
              table,td, th{
                  text-align:center;
                  border: 1px solid;
                  border-collapse: collapse;
                  padding: 1.5px 3px;
              }
              table{
                width:100%;
              }
              @media print{
                  *{
                    visibility:visible;
                  }
              }
              .flex{
                display: flex;
                justify-content: space-between;
                margin: 2em 0;
              }
              .flex div:nth-child(2)
              {
                  margin-right: 10%;
              }
          </style>
      </head>
      <body>
      <h4 style="width:80%;text-align:center;margin:1em 10%;">Azərbaycan Respublikası Dövlət Gömrük Komitəsi <br>
      "Azərterminalkompleks" Birliyi</h4>
      <div class="flex">
        <div>
          <p>Ödəyici:${service.clientFullName}</p>
          <p>VÖEN:${service.clientVoenorPassportNo}</p>
        </div>
        <div>
          <p>№: ${String(service.saleId).padStart(10, "0")}</p>
          <p>Ödəniş tarixi : ${service.saleDate}</p>
        </div>
      </div>
      <small>Ünvan</small>
      <table>
          <thead>
            <tr>
              <th>Xidmətin adı</th>
              <th>İşin sayı</th>
              <th>Əsas məbləğ</th>
              <th>ƏDV</th>
              <th>CƏM</th>
            </tr>
          </thead>
          <tbody>`;
      var totalQuantity = 0,
        totalPrice = 0,
        totalAmount = 0;
      service.saleDetails.forEach(x => {
        totalQuantity += x.saleDetailQuantity;
        totalPrice += x.saleDetailPrice;
        totalAmount += x.saleDetailAmount;
        a += `
        <tr>
          <td>${x.serviceName}</td>
          <td>${x.saleDetailQuantity}</td>
          <td>${Number(x.saleDetailAmount).toFixed(2)}</td>
          <td>${Number(x.saleDetailVatamount).toFixed(2)}</td>
          <td>${Number(x.saleDetailTotalAmount).toFixed(2)}</td>
        </tr>`;
      });

      a += `
        <tr>
          <td>Cəmi</td>
          <td>${totalQuantity}</td>
          <td>${Number(service.saleDetailAmount).toFixed(2)}</td>
          <td>${Number(service.saleTotalVatamount).toFixed(2)}</td>
          <td>${Number(service.saleTotalAmount).toFixed(2)}</td>
        </tr>
        </tbody>
        </table>
          <p>  GB və TXG üzrə ${userPrecinct} şöbəsi</p>
          <p style="text-decoration: underline">${userFullName}</p>
          <div class="flex print-text">
            <div>
                <p>Azərbaycan Respublikası Dövlət Gömrük Komitəsinin </p>
                <p>“Azərterminalkompleks” Birliyi</p>
                <p>VÖEN : 1802077241</p>
                <p>Hesab nömrəsi : AZ87PAHA38090AZNHC0100070889</p>
                <p>İdentifikasiya nömrəsi : 000708889</p>
                <p>“PAŞA Bank ASC” </p>
                <p>Kod : 505141</p>
                <p>VÖEN : 170767721</p>
                <p>S.W.I.F.T : PAHAAZ22</p>
                <p>Müxbir hesab : AZ82NABZ01350100000000071944</p>
            </div>
            <div>
                <p>Azərbaycan Respublikası Dövlət Gömrük Komitəsinin</p>
                <p>“Azərterminalkompleks” Birliyi  (ƏDV)</p>
                <p>VÖEN : 1802077241</p>
                <p>Dövlət Xəzinədarlıq Agentliyi (ƏDV)</p>
                <p>Kod : 69001</p>
                <p>VÖEN : 1401555071</p>
                <p>Müxbir hesab : AZ07NABZ01360100000000004944</p>
                <p>S.W.I.F.T BIK : CTREAZ24</p>
                <p>Hesab  № : AZ48CTRE00000000000000014001</p>
            </div>
          </div>
        </body>
      </html>`;
      var WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );
      WinPrint.document.write(a);
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    } else {
      alert("Məlumat daxil edilməyib");
    }
  }

  const handleChange = (event, value) => {
    setPage(Number(value));
  };
  const fetchData = async () => {
    try {
      const res = await sendRequest(
        "GET",
        `Inspector/SaleList?take=${take}&page=${page}&name=${name}&voenOrPassport=${voen}&status=${status}&dqn=${dqn}`,
        null
      );
      console.log(res);
      if (res.isSuccess) {
        console.log(res);
        setresult(res.result.data);
        console.log(res.result.data);
        setCountpage(res.result.totalPage);
      } else {
        dispatch(
          changeToast({ loading: false, type: false, message: res.message })
        );
      }
    } catch (err) {
      console.error(err);
      dispatch(
        changeToast({ loading: false, type: false, message: err.message })
      );
    }
    setLoad(false);
    setpge(false);
  };
  useEffect(() => {
    console.log(pge);
    console.log(page);
    if (pge) {
      setPage(1);
    }
    fetchData();
  }, [page, name, voen, status, dqn, take]);

  const noPrint = true;
  useEffect(() => {
    const handleKeyDown = e => {
      if (e.ctrlKey && e.key === "p") {
        if (noPrint) {
          e.preventDefault();
          e.stopPropagation();
          if (service !== null) {
            printDefault();
          }
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [service]);

  useEffect(() => {
    if (result && result.length > 0) {
      let totalPrice = 0;
      result.forEach(row => {
        totalPrice += Number(row.totalPrice);
      });
      setTotalTotalPrice(totalPrice);
    }
  }, [result]);

  useEffect(() => {
    if (result && result.length > 0) {
      let totalVatAmount = 0;
      result.forEach(row => {
        totalVatAmount += Number(row.vatAMount);
      });
      setTotalVatAmountSum(totalVatAmount);
    }
  }, [result]);

  useEffect(() => {
    if (result && result.length > 0) {
      let totalAmount = 0;
      result.forEach(row => {
        totalAmount += row.totalAmount;
      });
      setTotalAmountSum(totalAmount);
    }
  }, [result]);

  return result ? (
    <>
      <div className={style.TableMain}>
        <table className={style.Table}>
          <thead className={style.TableHeada}>
            <tr>
              <th>Tarix</th>
              <th>Status</th>
              <th>Müştəri</th>
              <th>Müştəri kodu</th>
              <th>VÖEN/Pasport</th>
              <th>Məbləğ</th>
              <th>ƏDV</th>
              <th>Cəm</th>
              <th>#</th>
            </tr>
          </thead>
          <tbody className={style.TableBody}>
            {result?.length > 0 ? (
              result?.map((row, rowIndex) => {
                return (
                  <tr key={rowIndex}>
                    <td>{row.saleDate}</td>

                    <td>
                      <span className={style["Status" + row.salePayStatusId]}>
                        {row.payStatusName}
                      </span>
                    </td>

                    <td title={row.clientFullName}>
                      {row.clientFullName.length > 60
                        ? row.clientFullName.substring(0, 30) + "..."
                        : row.clientFullName}
                    </td>
                    <td>{row.saleClientCode}</td>
                    <td>{row.clientVoenorPassportNo}</td>
                    <td>
                      {row.totalAmount.toLocaleString("tr-TR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </td>
                    <td>{row.vatAMount}</td>
                    <td>
                      {Number(row.totalPrice).toLocaleString("tr-TR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </td>
                    <td>
                      <span
                        className={style.info}
                        onClick={x => handlOpenModal(true, row.saleId)}
                      >
                        <i class="fa-solid fa-info"></i>
                      </span>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td>Məlumat yoxdur</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className={style.paginate}>
        <div className={style.PaginationSelect}>
          <Select2Take onChange={e => setTake(e.value)} value={take} />
        </div>
        <Stack spacing={2}>
          {result ? (
            <Pagination
              count={countpage}
              color="primary"
              page={page}
              onChange={handleChange}
            />
          ) : (
            ""
          )}
        </Stack>
        <div className={style.paginateBetween}>
          <span className={style.CemMebleg}>
            {totalAmountSum.toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </span>
          <span className={style.CemEDV}>
            {totalVatAmountSum.toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </span>

          <span className={style.TotalCemToplam}>
            {totalTotalPrice.toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </span>
        </div>
      </div>

      <Modal
        isOpen={isOpen}
        handleClose={() => handlOpenModal(false, null)}
        containerVariant={containerVariant}
        width={"80%"}
        height={"auto"}
        left={"left"}
        leftcount={"50%"}
        side={"top"}
        sidecount={"-50%"}
        bgcolor="#FFF"
        showd={"none"}
        clshow={false}
      >
        <ModalContent>
          <div className={style.ServiceDeatilMain}>
            <div className={style.ServiceDHead}>
              <div className={style.HeadMain}>
                <div className={style.HeadTitle}>
                  <h3>Satışın reyestiri</h3>
                </div>
                <ul className={style.HeadBody}>
                  <li>
                    <button
                      className={style.HeadPrintMCup}
                      onClick={() => printDefault()}
                    >
                      <i class="fa-solid fa-print"></i>
                      <span>Çap edin</span>
                    </button>
                  </li>
                  <li>
                    <i
                      className="fa-solid fa-xmark mainModalX"
                      onClick={() => {
                        handlOpenModal(false, null);
                      }}
                    ></i>
                  </li>
                </ul>
              </div>
            </div>
            <div className={style.ServiceDBody}>
              <div className={style.ServiceDBodyHead}>
                <ul className={style.FormItems}>
                  <li className={style.FormItem}>
                    <label>Müştəri tipi</label>
                    <span>{service?.clientTypeName}</span>
                  </li>
                  <li className={style.FormItem}>
                    <label>VÖEN/Pasport</label>
                    <span>{service?.clientVoenorPassportNo}</span>
                  </li>
                  <li className={`${style.FormItem} ${style.LongItem}`}>
                    <label>Müştəri adı</label>
                    <span>{service?.clientFullName}</span>
                  </li>
                  <li className={style.FormItemAzad}>
                    <label>Ödənişdən azad</label>
                    <span>
                      {service?.salePaymentType == 3 ||
                      service?.salePaymentType == 1
                        ? "Bəli"
                        : "Xeyr"}
                    </span>
                  </li>
                  <li className={style.FormItemAzad}>
                    <label>ƏDV-dən azad</label>
                    <span>
                      {service?.salePaymentType == 3 ||
                      service?.salePaymentType == 2
                        ? "Bəli"
                        : "Xeyr"}
                    </span>
                  </li>
                  <li className={style.FormItem}>
                    <label>DQN</label>
                    <span>
                      {service?.saleCarNumber === ""
                        ? "Yoxdur"
                        : service?.saleCarNumber}
                    </span>
                  </li>
                  <li className={style.FormItem}>
                    <label>Tarix</label>
                    <span>{service?.saleDate}</span>
                  </li>
                  <li className={`${style.FormItem} ${style.FormShort}`}>
                    <label>Bəyannamə</label>
                    <span>
                      {service?.saleDeclarationNo
                        ? service.saleDeclarationNo
                        : "kod"}
                    </span>
                  </li>
                </ul>
              </div>
              <div className={style.ServiceDBodyTable}>
                <table className={style.NewSericeTable}>
                  <thead className={style.TableHeada}>
                    <tr>
                      <th>No:</th>
                      <th>Xidmətin adı</th>
                      <th>Ölçü vahidi</th>
                      <th>Miqdarı</th>
                      <th>Qiyməti</th>
                      <th>Məbləği</th>
                      <th>ƏDV dərəcəsi</th>
                      <th>ƏDV məbləği</th>
                      <th>Cəmi məbləği</th>
                    </tr>
                  </thead>
                  <tbody className={style.TableBody}>
                    {service?.saleDetails.map((x, i) => {
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{x.serviceName}</td>
                          <td>{x.serviceUnit}</td>
                          <td>{x.saleDetailQuantity}</td>
                          <td>{Number(x.saleDetailPrice).toFixed(2)}</td>
                          <td>{Number(x.saleDetailAmount).toFixed(2)}</td>
                          <td>{x.saleDetailVatrate}%</td>
                          <td>{Number(x.saleDetailVatamount).toFixed(2)}</td>
                          <td>{Number(x.saleDetailTotalAmount).toFixed(2)}</td>
                        </tr>
                      );
                    })}
                    <tr className={style.TotalAmount}>
                      <td></td>
                      <td>Cəmi</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{Number(service?.saleDetailAmount).toFixed(2)} ₼</td>
                      <td></td>
                      <td>
                        {Number(service?.saleTotalVatamount).toFixed(2)} ₼
                      </td>
                      <td>{Number(service?.saleTotalAmount).toFixed(2)} ₼</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </ModalContent>
      </Modal>
    </>
  ) : (
    <Loader></Loader>
  );
};

export default Paginations;
