import React, { useState } from "react";
import style from "./index.module.css";
import Paginations from "./Paginations";
const EQayime = () => {
  const [filter, setFilter] = useState(false);
  const [status, setStatus] = useState(null);
  const [date, setDate] = useState(null);
  const [code, setCode] = useState(null);
  const [number, setNumber] = useState(null);
  const [voen, setVoen] = useState(null);
  return (
    <div className={style.container}>
      <div className={style.Head}>
        <div className={style.HeadMain}>
          <div className={style.HeadTitle}>
            <h3>ELEKTRON QAİMƏLƏR ÜZRƏ REYESTR</h3>
          </div>
          <ul className={style.HeadBody}>
            <li>
              <img
                onClick={e => setFilter(!filter)}
                className={filter ? style.FilterActive : style.Filter}
                src={"/icons/filterdark.svg"}
                alt=""
              />
            </li>
            <li>
              <button className={style.HeadAddSend}>
                <img src="/icons/share-solid 1.svg" alt="" />
                <span>Göndər</span>
              </button>
            </li>
            <li>
              <button className={style.HeadExcellButton}>
                <img src="/icons/file-excel-solid 1.svg" alt="" />
                <span>Excel-i yüklə</span>
              </button>
            </li>
          </ul>
        </div>
        <div className={filter ? style.HeadFilteractive : style.HeadFilter}>
          <ul className={style.FilterItems}>
            <li className={style.FilterItem}>
              <label>Tarix</label>
              <input
                placeholder="Tarix"
                onChange={e => setDate(e.target.value)}
              />
            </li>
            <li className={style.FilterItem}>
              <label>Nömrə</label>
              <input
                placeholder="Nömrə"
                type="number"
                onChange={e => setNumber(e.target.value)}
              />
            </li>
            <li className={style.FilterItem}>
              <label>E-qaimənin statusu</label>
              <select onChange={e => setStatus(e.target.value)}>
                <option>Status</option>
                <option value={1}>Rezident</option>
                <option value={2}>Qeyri rezident</option>
              </select>
            </li>
            <li className={style.FilterItem}>
              <label>Şirkətin adı</label>
              <select onChange={e => setStatus(e.target.value)}>
                <option>Şirkətin adı</option>
                <option value={1}>ad</option>
                <option value={2}>ad2</option>
              </select>
            </li>
            <li className={style.FilterItem}>
              <label>VÖEN</label>
              <input
                placeholder="VÖEN"
                onChange={e => setVoen(e.target.value)}
              />
            </li>
            <li className={style.FilterItem}>
              <label>Müştəri kodu</label>
              <input
                placeholder="Müştəri kodu"
                onChange={e => setCode(e.target.value)}
              />
            </li>
          </ul>
        </div>
      </div>
      <div className={style.Body}>
        <Paginations status={status} date={date} number={number} code={code} />
      </div>
    </div>
  );
};

export default EQayime;
