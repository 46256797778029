import React from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Pages/Login/Login";
import "./App.css";
import Layout from "./Components/Layout/Layout";
import Home from "./Pages/Home/Home";
import NoPage from "./Pages/Nopage/NoPage";
import Services from "./Pages/ServiceCrm/Services/Service/Service";
import NewService from "./Pages/ServiceCrm/Services/NewService/NewService";
import EQayime from "./Pages/ServiceCrm/EQayime/EQayime";
import Detail from "./Pages/ServiceCrm/EQayime/Detail";
import Customer from "./Pages/ServiceCrm/Customer/Customer";
import RequiredAuth from "./Services/RequiredAuth";
import PriceList from "./Pages/ServiceCrm/PriceList/PriceList";
import ForgotPassword from "./Pages/Login/ForgotPassword";
import ChangePassword from "./Pages/Login/ChangePassword";
// import ScreenSizeWatcher from "./Pages/ScreenSizeWatcher";

function App() {
  return (
    <Router>
      {/* <ScreenSizeWatcher /> */}

      <Routes>
        <Route element={<RequiredAuth />}>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/Services" element={<Services />} />
            <Route path="/NewService/:voen?" element={<NewService />} />
            <Route path="/EQayime" element={<EQayime />} />
            <Route path="/Customer" element={<Customer />} />
            <Route path="/PriceList" element={<PriceList />} />
            <Route path="/*" element={<NoPage />} />
            <Route path="/EQayime/Detail/:id?" element={<Detail />} />
          </Route>
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/ForgotPassword" element={<ForgotPassword />} />
        <Route path="/ChangePassword/:token?" element={<ChangePassword />} />
      </Routes>
    </Router>
  );
}

export default App;
