import React, { useState, useEffect } from "react";
import style from "./index.module.css";
import Modal from "../Modal/Modal";
import styled from "styled-components";
import usePrivate from "./../../Services/useAxiosPrivate2";
import { useDispatch, useSelector } from "react-redux";
import { changeNot } from "../../Store/auth";
import Loader from "../Loader/Loader";
const ModalContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  h1 {
    color: #5c3aff;
  }
`;
const containerVariant = {
  initial: { top: "-100%", transition: { type: "spring" } },
  isOpen: { top: "50%" },
  exit: { top: "-100%" },
};
const Notifigation = () => {
  const dispatch = useDispatch();
  const { sendRequest } = usePrivate();
  const [db, setDb] = useState();

  const [isOpen, toggle] = useState(false);
  const [mobValue, setmobmodal] = useState();
  function handlOpenModal(open) {
    toggle(open);
    // console.log(mobValue);
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await sendRequest("GET", `Inspector/GetNote`, null);
        dispatch(changeNot({ message: res.message }));
        console.log(res);
        if (res.isSuccess) {
          setDb(res.result);
        }
        // setresult(res.data);
        // setCountpage(res.totalPage);
      } catch (err) {
        console.error(err);
        dispatch(changeNot({ message: err.message }));
      }
    };

    fetchData();
  }, []);
  // throw new Error("hata yaptin!");
  return (
    <div className={style.Main}>
      <div className={style.Title}>
        <h3>Bildirişlər</h3>
      </div>
      <ul className={style.Notification}>
        {db ? (
          db.length > 0 ? (
            db?.map((e, i) => {
              return (
                <li
                  key={e.notificationId}
                  onClick={x => {
                    handlOpenModal(true);
                    setmobmodal(e);
                  }}
                >
                  <div className={style.Bell}>
                    <div className={`${style.BellDiv} ${style.deactive}`}>
                      <i class="fa-regular fa-bell"></i>
                    </div>
                  </div>
                  <div className={style.Message}>
                    <div className={style.MessageText}>
                      <p>{e.notificationTitle}</p>
                    </div>
                    <div className={style.MessageTime} s>
                      <span>{e.notificationSendDate}</span>
                      <img src={"/icons/clock 1.svg"} alt="" />
                    </div>
                  </div>
                </li>
              );
            })
          ) : (
            <li>Məlumat yoxdur</li>
          )
        ) : (
          <Loader></Loader>
        )}
      </ul>
      <div>
        <Modal
          isOpen={isOpen}
          handleClose={() => handlOpenModal(false, null)}
          containerVariant={containerVariant}
          width={"50%"}
          height={"auto"}
          left={"left"}
          leftcount={"50%"}
          side={"top"}
          sidecount={"50%"}
          clshow={false}
          showd={"none"}
        >
          <ModalContent>
            {mobValue ? (
              <div className={style.modalBody}>
                <div className={style.ModalHead}>
                  <h3>Mövzu</h3>
                  <button
                    style={{ cursor: "pointer" }}
                    onClick={() => handlOpenModal(false, null)}
                  >
                    <i class="fa-solid fa-xmark"></i>
                  </button>
                </div>
                <div className={style.MessageAbout}>
                  <ul>
                    <li>
                      <span>Göndərən:</span>
                      <span>{mobValue.senderFullName}</span>
                    </li>
                    <li>
                      <span>Qəbul eden:</span>
                      <span>{mobValue.readerFullName}</span>
                    </li>
                  </ul>
                  <p>{mobValue.notificationSendDate}</p>
                </div>
                <div className={style.MessageModal}>
                  <div className={style.MessageMod}>
                  <h3>{mobValue.notificationTitle}</h3>
                  <p>{mobValue.notificationText}</p>
                  </div>
                </div>
              </div>
            ) : null}
          </ModalContent>
        </Modal>
      </div>
    </div>
  );
};

export default Notifigation;
