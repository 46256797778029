import React, { useState, useEffect } from "react";
import style from "./index.module.css";
import usePrivate from "../../../Services/useAxiosPrivate2";
import { changeToast } from "../../../Store/auth";
import { useDispatch } from "react-redux";
import Loader from "../../../Components/Loader/Loader";

const PriceList = () => {
  const dispatch = useDispatch();
  const { sendRequest } = usePrivate();
  const [filter, setFilter] = useState(false);
  const [db, setDb] = useState();
  // const [totalPrice, setTotalPrice] = useState(0);
  // const [totalEDV, setTotalEDV] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await sendRequest("GET", `Inspector/GetServicePrice`, null);
        console.log(res);
        if (res.isSuccess) {
          setDb(res.result);
        } else {
          dispatch(
            changeToast({ loading: false, type: false, message: res.message })
          );
        }
      } catch (err) {
        console.error(err);
        dispatch(
          changeToast({ loading: false, type: false, message: err.message })
        );
      }
    };

    fetchData();
  }, []);


  // useEffect(() => {
  //   if (db) {
  //     const total = db.reduce((acc, row) => acc + (row.price * 0.18 + row.price), 0);
  //     setTotalPrice(total);
      
  //     const totalEDV = db.reduce((acc, row) => acc + (row.price * 0.18), 0);
  //     setTotalEDV(totalEDV);
  //   }
  // }, [db]);

  
  return (
    <div className={style.container}>
      <div className={style.Head}>
        <div className={style.HeadMain}>
          <div className={style.HeadTitle}>
            <h3>Xidmətlərin qiymət cədvəli</h3>
          </div>
        </div>
      </div>
      {db ? (
        <div className={style.Body}>
          <table className={style.Table}>
            <thead className={style.TableHeada}>
              <tr>
                <th>No</th>
                <th>Ad</th>
                <th>Qiyməti</th>
                <th>ƏDV</th>
                <th>Cəmi</th>
              </tr>
            </thead>
            <tbody className={style.TableBody}>
              {db?.length > 0 ? (
                db?.map((row, i) => {
                  const totalForRow = row.price * 0.18 + row.price;
                  return (
                    <tr key={i}>
                      <td>
                        <div className={style.TableBodyFirstTd}>{i + 1}</div>
                      </td>
                      <td>
                        <div>{row.name}</div>
                      </td>
                      <td>
                        <div>
                          {Number(row.price).toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}₼
                        </div>
                      </td>
                      <td>
                        <div>
                          {(row.price * 0.18).toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}₼
                        </div>
                      </td>
                      <td>
                        <div className={style.TableBodyLastTd}>
                          {totalForRow.toLocaleString("tr-TR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}₼
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td>Məlumat yoxdur</td>
                </tr>
              )}
            </tbody>
            {/* <tfoot className={style.tableFixedTotal}>
              <tr>
                <th>No</th>
                <th>Ad</th>
                <th className={style.footQiymet}>
                  {db?.length > 0 &&
                    db.reduce((acc, row) => acc + row.price, 0).toLocaleString("tr-TR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}₼
                </th>
                <th className={style.footEDV}>
                  {totalEDV.toLocaleString("tr-TR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}₼
                </th>
                <th className={style.footCem}>
                  {totalPrice.toLocaleString("tr-TR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}₼
                </th>
              </tr>
            </tfoot> */}
          </table>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default PriceList;
