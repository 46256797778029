import React, { useEffect, useState } from "react";
import style from "./index.module.css";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import usePrivate from "./../../../Services/useAxiosPrivate2";
import { changeToast } from "../../../Store/auth";
import { useDispatch } from "react-redux";
import Select2Take from "../../../Components/Select/SelectTake2";
import Loader from "../../../Components/Loader/Loader";
const Paginations = ({
  name,
  voen,
  load,
  setLoad,
  code,
  double,
  funcval,
  pge = false,
  setpge,
}) => {
  const dispatch = useDispatch();
  const { sendRequest } = usePrivate();
  const [result, setresult] = useState();
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(25);
  const [countpage, setCountpage] = useState();

  const handleChange = (event, value) => {
    setPage(Number(value));
  };
  const fetchData = async () => {
    try {
      const res = await sendRequest(
        "GET",
        `Inspector/ClientList?page=${page}&take=${take}&name=${name}&voenOrPassport=${voen}&clientCode=${code}`,
        null
      );
      console.log(res.result);
      if (res.isSuccess) {
        setresult(res.result.data);
        setLoad(false);
        setCountpage(res.result.totalPage);
      } else {
        dispatch(
          changeToast({ loading: false, type: false, message: res.message })
        );
      }
    } catch (err) {
      console.error(err);
      dispatch(
        changeToast({ loading: false, type: false, message: err.message })
      );
    }
    setLoad(false);
    setpge(false);
  };
  useEffect(() => {
    console.log("satrt");
    if (pge) {
      setPage(1);
    }
    setLoad(true);
    fetchData();
  }, [page, take, voen, name, code]);

  return result ? (
    <>
      <div className={style.Table}>
        <table>
          <thead className={style.TableHeada}>
            <tr>
              <th>Ad</th>
              <th>VÖEN/Pasport</th>
              <th>Müştəri kodu</th>
            </tr>
          </thead>
          <tbody className={style.TableBody}>
            {result.length > 0 ? (
              result?.map((row, rowIndex) => (
                <tr
                  key={row.clientId}
                  onDoubleClick={() =>
                    funcval
                      ? funcval(
                          row.clientId,
                          row.clientFullName,
                          row.clientVoenorPassportNo
                        )
                      : ""
                  }
                >
                  <td>{row.clientFullName}</td>
                  <td>{row.clientVoenorPassportNo}</td>
                  <td>{row.clientCode}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td>Məlumat yoxdur</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className={style.paginate}>
        <div className={style.PaginationSelect}>
          <Select2Take onChange={e => setTake(e.value)} value={take} />
        </div>
        <Stack spacing={2}>
          <Pagination
            count={countpage}
            color="primary"
            page={page}
            onChange={handleChange}
          />
        </Stack>
      </div>
    </>
  ) : (
    <Loader></Loader>
  );
};

export default Paginations;
