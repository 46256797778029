import React from "react";
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  render() {
    if (this.state.error) {
      // You can render any custom fallback UI
      //   return this.props.fallback;
      return (
        <div>
          <h1>{this.state.error.stack}</h1>
        </div>
      );
    }

    return this.props.children;
  }
}
