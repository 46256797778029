import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Example from "./Example";
import Clients from "../../Pages/ServiceCrm/Customer/Customer";
const ModalContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  resize: both;
`;
const containerVariant = {
  initial: { right: "-45%", transition: { type: "spring" } },
  isOpen: { right: "-3%" },
  exit: { right: "-45%" },
};
function ModalSelect({
  setOpen,
  open,
  type,
  width,
  height,
  setVal,
  funcval = null,
}) {
  const Tip = () => {
    console.log("MIDSL");
    if (type === 1) {
      return <Clients double={setVal} funcval={funcval} />;
    }
  };
  return (
    <Example
      setOpen={setOpen}
      open={open}
      children={<Clients double={setVal} funcval={funcval} />}
    >
      <Clients double={setVal} funcval={funcval} />
    </Example>
    // <Modal
    //   isOpen={open}
    //   handleClose={() => {
    //     setOpen(false);
    //   }}
    //   containerVariant={containerVariant}
    //   width={width}
    //   height={height}
    //   left={"top"}
    //   leftcount={"50%"}
    //   side={"right"}
    //   sidecount={"0%"}
    //   showd={"block"}
    // >
    //   <ModalContent>
    //     <Tip />
    //   </ModalContent>
    // </Modal>
  );
}

export default ModalSelect;
