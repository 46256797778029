import React from "react";
import style from "./index.module.css";
import Notifigation from "../../Components/Notifigation/Notifigation";
import ErrorBoundary from "../../Components/Error/ErrorBoundary";
const Home = () => {
  return (
    <div className={style.container}>
      <div className={style.Body}>
        <ErrorBoundary>
          <Notifigation />
        </ErrorBoundary>
      </div>
    </div>
  );
};

export default Home;
