import React, { useEffect, useState } from "react";
import style from "./index.module.css";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { NavLink } from "react-router-dom";
import { changeToast } from "../../../Store/auth";
import { useDispatch } from "react-redux";
const data = [];
// const data = [
//   {
//     Tarix: "10.01.2024 14:55",
//     Nömrə: "00000004",
//     Status: 1,
//     Müştəri: "Siesco MMC",
//     "Müştəri kodu": "1278383996",
//     "Pasport nömrəsi": "AA3282829",
//     DQN: "99AA999",
//   },
//   {
//     Tarix: "10.02.2024 14:44",
//     Nömrə: "00000004",
//     Status: 2,
//     Müştəri: "Siesco MMC",
//     "Müştəri kodu": "1278383996",
//     "Pasport nömrəsi": "AA3282829",
//     DQN: "99AA999",
//   },
//   {
//     Tarix: "10.02.2024 14:44",
//     Nömrə: "00000004",
//     Status: 3,
//     Müştəri: "Siesco MMC",
//     "Müştəri kodu": "1278383996",
//     "Pasport nömrəsi": "AA3282829",
//     DQN: "99AA999",
//   },
//   {
//     Tarix: "10.02.2024 14:44",
//     Nömrə: "00000004",
//     Status: 3,
//     Müştəri: "Siesco MMC",
//     "Müştəri kodu": "1278383996",
//     "Pasport nömrəsi": "AA3282829",
//     DQN: "99AA999",
//   },
//   {
//     Tarix: "10.12.2024 14:04",
//     Nömrə: "00000004",
//     Status: 1,
//     Müştəri: "Siesco MMC",
//     "Müştəri kodu": "1278383996",
//     "Pasport nömrəsi": "AA3282829",
//     DQN: "99AA999",
//   },
//   {
//     Tarix: "10.02.2024 14:44",
//     Nömrə: "00000004",
//     Status: 2,
//     Müştəri: "Siesco MMC",
//     "Müştəri kodu": "1278383996",
//     "Pasport nömrəsi": "AA3282829",
//     DQN: "99AA999",
//   },
//   {
//     Tarix: "10.02.2024 14:44",
//     Nömrə: "00000004",
//     Status: 4,
//     Müştəri: "Siesco MMC",
//     "Müştəri kodu": "1278383996",
//     "Pasport nömrəsi": "AA3282829",
//     DQN: "99AA999",
//   },
//   {
//     Tarix: "10.02.2024 14:44",
//     Nömrə: "00000004",
//     Status: 4,
//     Müştəri: "Siesco MMC",
//     "Müştəri kodu": "1278383996",
//     "Pasport nömrəsi": "AA3282829",
//     DQN: "99AA999",
//   },
//   {
//     Tarix: "10.02.2024 14:44",
//     Nömrə: "00000004",
//     Status: 2,
//     Müştəri: "Siesco MMC",
//     "Müştəri kodu": "1278383996",
//     "Pasport nömrəsi": "AA3282829",
//     DQN: "99AA999",
//   },
// ];
const Paginations = (status, date, number, code) => {
  const dispatch = useDispatch();
  const [result, setresult] = useState([]);
  const [page, setPage] = useState(1);
  const [countpage, setCountpage] = useState();
  useEffect(() => {
    for (let i = 0; i < 13; i++) {
      const statusOptions = [1, 2, 3];
      const randomIndex = Math.floor(Math.random() * statusOptions.length);
      const randomStatus = statusOptions[randomIndex];
      data.push({
        Tarix: "10.02.2024",
        Nömrə: `0000000${i + 1}`,
        Müəssisə: "Azterminal...",
        Müştəritipi:
          randomStatus === 1
            ? "Huquqi"
            : randomStatus === 2
            ? "rezident"
            : randomStatus === 3
            ? "no rezident"
            : "diplomat",
        VÖEN: "14015550...",
        Müştəri: `Siesco MMC ${i + 1}`,
        Müştərikodu: `Customer Code ${i + 1}`,
        Strukturbölmə:
          randomStatus == 1
            ? "Qırmızı körp..."
            : randomStatus == 2
            ? "Astara GP"
            : randomStatus === 3
            ? "Bilesuvar GP"
            : randomStatus === 4
            ? "...GP"
            : "...GP",
        Məsulşəxs:
          randomStatus == 1
            ? "Mahir..."
            : randomStatus === 2
            ? "Ayaz..."
            : randomStatus === 3
            ? "Samir ...."
            : randomStatus === 4
            ? "Nurlan ..."
            : "Xanmem...",
        DQN: `99AA999${i + 1}`,
        Pasportnömrəsi: `AA328282${i + 1}`,
        Status: "-",
        Qeyd: "-",
      });
    }
  }, []);
  const handleChange = (event, value) => {
    setPage(Number(value));
  };
  useEffect(() => {
    console.log(data);
    const calculatedCountPage = Math.ceil(data.length / 6);
    setCountpage(calculatedCountPage);
    const startIndex = (page - 1) * 6;
    const endIndex = startIndex + 6;
    setresult(data.slice(startIndex, endIndex));
  }, [page, status, date, number, code]);

  return result !== null ? (
    <>
      <div className={style.Table}>
        <ul className={style.TableHeada}>
          <li>Tarix</li>
          <li>Nömrə</li>
          <li>Müəssisə</li>
          <li>Müştəri tipi</li>
          <li>VÖEN</li>
          <li>Müştəri</li>
          <li>Müştəri kodu</li>
          <li>Struktur bölmə</li>
          <li>Məsul şəxs</li>
          <li>DQN</li>
          <li>Pasport nömrəsi</li>
          <li>Status</li>
          <li>Qeyd</li>
        </ul>
        <ul className={style.TableBody}>
          {result?.map((row, rowIndex) => (
            <NavLink to={`/EQayime/Detail/${row.Nömrə}`}>
              <li key={rowIndex}>
                {Object.values(row).map((value, columnIndex) => {
                  return <span key={columnIndex}>{value}</span>;
                })}
              </li>
            </NavLink>
          ))}
        </ul>
      </div>
      <div className={style.paginate}>
        <Stack spacing={2}>
          <Pagination
            count={countpage}
            color="primary"
            page={page}
            onChange={handleChange}
          />
        </Stack>
      </div>
    </>
  ) : (
    <>Loading</>
  );
};

export default Paginations;
