import React, { useState } from "react";
import style from "./index.module.css";
import logo from "../../Images/logo (1).png";
import { NavLink } from "react-router-dom";
import lefcircle from "../../Images/Group 22.png";
import lefcircle2 from "../../Images/Group 21.png";
import { logout } from "../../Store/auth";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
const menu = [
  {
    to: "/",
    rolekey: "Home",
    text: "Ana səhifə",
    icon: "home 2 (1).svg",
    altmenu: null,
  },
  {
    to: "/Services",
    rolekey: "Case",
    text: "Xidmətlərin göstəril...",
    icon: "sell.svg",
  },
  {
    to: "/Customer",
    rolekey: "Case",
    text: "Müştərilər",
    icon: "contacts_FILL0_wght400_GRAD0_opsz24 2.svg",
  },
  {
    to: "/PriceList",
    rolekey: "Case",
    text: "Qiymət cədvəli",
    icon: "price-books-svgrepo-com 1.svg",
  },
];
const Nav = ({ setMinimize, minimize }) => {
  // const [minimize, setminize] = useState(true);
  const [opnsub, setSub] = useState({
    open: false,
    index: null,
  });
  const { userFullName } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
    // dispatch(changeStatus({ status: null }));
  };

  const subopen = async i => {
    console.log(i);
    setSub({
      open: !opnsub.open,
      index: i,
    });
    console.log(opnsub);
  };
  return (
    <nav className={minimize ? style.minimized : ""}>
      <img src={lefcircle} alt="" className={style.lefcircle} />
      <img src={lefcircle} alt="" className={style.lefcircle2} />
      <div
        className={style.NavLogo}
        style={minimize ? { justifyContent: "center" } : {}}
      >
        <div>
          {minimize ? (
            <div
              style={{
                justifyContent: "center",
                textAlign: "center",
                width: "100%",
                transition: "1s",
              }}
            >
              <div
                className={style.NavFooter}
                onClick={e => {
                  localStorage.setItem("minimized", !minimize);
                  setMinimize(!minimize);
                }}
              >
                <i
                  className="fa-solid fa-thumbtack"
                  style={
                    minimize
                      ? { transform: "rotate(45deg)", marginRight: "0px" }
                      : {}
                  }
                ></i>
              </div>
            </div>
          ) : (
            <>
              <div
                style={{
                  transition: "1s",
                }}
              >
                {/* <img src={logo} /> */}
                <img
                  className={style.projectLogo}
                  src={"./icons/brand.png"}
                />
              </div>
              <div
                className={style.NavFooter}
                onClick={e => {
                  localStorage.setItem("minimized", !minimize);
                  setMinimize(!minimize);
                }}
              >
                <i
                  className="fa-solid fa-thumbtack"
                  style={
                    minimize
                      ? {
                          transform: "rotate(180deg)",
                          marginRight: "0px",
                          transition: "1s",
                        }
                      : {}
                  }
                ></i>
              </div>
            </>
          )}
        </div>
        <hr></hr>
      </div>
      <div className={style.NavBody}>
        <ul className={style.navUlfirst}>
          {menu.map((l, i) => {
            return (
              <li key={i} className={style.firstLi}>
                <NavLink to={l.to} className={style.firsta}>
                  <div className={style.navArrow}>
                    <img src={"/icons/" + l.icon} />
                    <span>{l.text}</span>
                  </div>
                </NavLink>
              </li>
            );
          })}
        </ul>
      </div>
      <div className={style.head_userinfo}>
        <ul style={minimize ? { justifyContent: "center" } : null}>
          {minimize ? (
            <li>
              <button onClick={handleLogout}>
                <i className="fa-solid fa-arrow-right-from-bracket"></i>
              </button>
            </li>
          ) : (
            <>
              <li>
                <i className={`fa-regular fa-user ${style.userinfoicon}`}></i>
                <span
                  className={style.userName}
                  style={{ whiteSpace: "nowrap" }}
                >
                  {userFullName}
                </span>
              </li>
              <li>
                <button onClick={handleLogout}>
                  <i className="fa-solid fa-arrow-right-from-bracket"></i>
                </button>
              </li>
            </>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default Nav;
