import React, { useState, useEffect } from "react";
import style from "./index.module.css";
import Paginations from "./Paginations";
import { NavLink } from "react-router-dom";

import { RotatingLines } from "react-loader-spinner";
function Service() {
  const [filter, setFilter] = useState(false);
  const [status, setStatus] = useState(0);
  const [name, setName] = useState("");
  const [voenPasp, setVoenpasp] = useState("");
  const [voenInput, setVoenInput] = useState("");
  const [dqn, setDdn] = useState("");
  const [display, setDisplay] = useState(false);
  const [printDisplay, setPrintDisplay] = useState(false);
  const [load, setLoad] = useState(false);
  const [pge, setpge] = useState(false);
  const Handlesearch = e => {
    e.preventDefault();
    setLoad(true);
    setName(e.target.name.value);
    setVoenpasp(e.target.voen.value);
    setStatus(e.target.status.value);
    setDdn(e.target.dqn.value);
    setpge(true);
  };

  const onkeyDown = e => {
    if (e.key === "Enter") {
      console.log(e.target.value);
    }
  };
  return (
    <div className={style.container}>
      <div className={style.Head}>
        <div className={style.HeadMain}>
          <div className={style.HeadTitle}>
            <h3>Xidmətlərin göstərilməsi</h3>
          </div>
          <ul className={style.HeadBody}>
            <li>
              <img
                className="Filter"
                onClick={e => setFilter(!filter)}
                src={"/icons/filterdark.svg"}
                alt=""
              />
            </li>
            <li>
              <NavLink to="/NewService">
                <button className={style.HeadAddButton}>
                  <img src="/icons/pluscircle.svg" alt="" />
                  <span>Yeni</span>
                </button>
              </NavLink>
            </li>
          </ul>
        </div>
        <div className={filter ? style.HeadFilteractive : style.HeadFilter}>
          <form
            onKeyDown={e => onkeyDown(e)}
            style={{ width: "100%", padding: "0", margin: "0" }}
            onSubmit={e => Handlesearch(e)}
          >
            <ul className={style.FilterItems}>
              <li className={`${style.FilterItem} ${style.FilterLong}`}>
                <label>Müştəri adı</label>
                <input placeholder="Müştəri adı" type="text" name="name" />
              </li>
              <li className={style.FilterItem}>
                <label>VÖEN/Pasport</label>
                <input placeholder="VÖEN/Pasport" type="text" name="voen" />
              </li>
              <li className={style.FilterItem}>
                <label>Status</label>
                <select name="status">
                  <option value="">Status seç...</option>
                  <option value={1}>Natamam ödənilib</option>
                  <option value={2}>Ödənilib</option>
                  <option value={4}>Ləğv edilib</option>
                  <option value={3}>Hazırlanıb</option>
                </select>
              </li>
              <li className={style.FilterItem}>
                <label>DQN</label>
                <input placeholder="DQN..." type="text" name="dqn" />
              </li>
              <li className={style.FilterItemButtons}>
                <button className={style.SearchButton}>
                  {" "}
                  {load ? (
                    <RotatingLines
                      visible={true}
                      height="15"
                      width="15"
                      strokeColor="black"
                      // color="white"
                      // strokeWidth="5"
                      animationDuration="0.75"
                      ariaLabel="rotating-lines-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  ) : (
                    "Axtar"
                  )}
                </button>
                <button
                  type="reset"
                  className={style.ResetButton}
                  onClick={() => {
                    setStatus(0);
                    setVoenpasp("");
                    setName("");
                    setDdn("");
                  }}
                >
                  <img src={"/icons/share-solid 1.svg"} alt="" />
                </button>
              </li>
            </ul>
          </form>
        </div>
      </div>

      <div className={style.Body}>
        <Paginations
          status={status}
          dqn={dqn}
          name={name}
          voen={voenPasp}
          setLoad={setLoad}
          pge={pge}
          setpge={setpge}
        />
      </div>
    </div>
  );
}

export default Service;
