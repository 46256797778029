import React, { useState, useEffect } from "react";
import style from "./index.module.css";
import Paginations from "./Paginations";
import { RotatingLines } from "react-loader-spinner";

function Customer({ double, funcval }) {
  const [filter, setFilter] = useState(false);
  const [voen, setvoen] = useState("");
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [load, setLoad] = useState(false);
  const [pge, setpge] = useState(false);
  const Handlesearch = e => {
    e.preventDefault();
    setpge(true);
    setName(e.target.name.value);
    setvoen(e.target.voen.value);
    setCode(e.target.code.value);
  };
  useEffect(() => {
    console.log("client start");
  }, []);

  const onkeyDown = e => {
    if (e.key === "Enter") {
      console.log(e.target.value);
    }
  };
  return (
    <div className={style.container}>
      <div className={style.Head}>
        <div className={style.HeadMain}>
          <div className={style.HeadTitle}>
            <h3>Müştərilərin siyahısı</h3>
          </div>
          <ul className={style.HeadBody}>
            <li>
              <img
                className="Filter"
                onClick={e => setFilter(!filter)}
                src={"/icons/filterdark.svg"}
                alt=""
              />
            </li>
          </ul>
        </div>
        <div className={filter ? style.HeadFilteractive : style.HeadFilter}>
          <form
            onKeyDown={e => onkeyDown(e)}
            style={{ width: "100%", padding: "0", margin: "0" }}
            onSubmit={e => Handlesearch(e)}
          >
            <ul className={style.FilterItems}>
              <li className={`${style.FilterItem} ${style.FilterItemAd}`}>
                <label>Ad</label>
                <input
                  placeholder="Ad..."
                  name="name"
                  // onChange={e => setName(e.target.value)}
                />
              </li>

              <li className={style.FilterItem}>
                <label>VÖEN</label>
                <input
                  placeholder="VÖEN..."
                  name="voen"
                  // onChange={e => setvoen(e.target.value)}
                  type="text"
                />
              </li>
              <li className={style.FilterItem}>
                <label>Müştəri kodu</label>
                <input
                  placeholder="Müştəri kodu..."
                  name="code"
                  // onChange={e => setvoen(e.target.value)}
                  type="text"
                />
              </li>
              <li className={style.FilterItemButtons}>
                <button className={style.SearchButton}>
                  {load ? (
                    <RotatingLines
                      visible={true}
                      height="15"
                      width="15"
                      strokeColor="black"
                      // color="white"
                      // strokeWidth="5"
                      animationDuration="0.75"
                      ariaLabel="rotating-lines-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  ) : (
                    "Axtar"
                  )}
                </button>
                <button
                  type="reset"
                  className={style.ResetButton}
                  onClick={() => {
                    setvoen("");
                    setName("");
                    setCode("");
                  }}
                >
                  <img src={"/icons/share-solid 1.svg"} alt="" />
                </button>
              </li>
            </ul>
          </form>
        </div>
      </div>
      <div className={style.Body}>
        <Paginations
          name={name}
          voen={voen}
          load={load}
          code={code}
          setLoad={setLoad}
          double={double}
          funcval={funcval}
          pge={pge}
          setpge={setpge}
        />
      </div>
    </div>
  );
}

export default Customer;
