import React, { useEffect, useState } from "react";
import style from "./index.module.css";
import { NavLink, useParams } from "react-router-dom";
import ModalUSer from "../Services/NewService/ModalUSer";
import styled from "styled-components";
const ModalContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 60px 30px;
`;
const data = [
  {
    Tarix: "10.02.2024",
    Nömrə: "00000004",
    Müəssisə: "Azterminal...",
    Müştəritipi: "rezident",
    VÖEN: "14015550...",
    Müştəri: "Siesco MMC",
    Müştərikodu: "1278383996",
    Strukturbölmə: "Astara GP",
    Məsulşəxs: "Nurlan Şü...",
    DQN: "99AA999",
    Pasportnömrəsi: "AA3282829",
    Status: "-",
    Qeyd: "-",
  },
  {
    Tarix: "10.02.2024",
    Nömrə: "00000005",
    Müəssisə: "Azterminal...",
    Müştəritipi: "rezident",
    VÖEN: "14015550...",
    Müştəri: "Siesco MMC",
    Müştərikodu: "1278383996",
    Strukturbölmə: "Qırmızı körp...",
    Məsulşəxs: "Xanmem...",
    DQN: "99AA999",
    Pasportnömrəsi: "AA3282829",
    Status: "-",
    Qeyd: "-",
  },
  {
    Tarix: "10.02.2024",
    Nömrə: "00000006",
    Müəssisə: "Azterminal...",
    Müştəritipi: "rezident",
    VÖEN: "140155502...",
    Müştəri: "Siesco MMC",
    Müştərikodu: "12782383996",
    Strukturbölmə: "Astara GP",
    Məsulşəxs: "Nurlan Şü...",
    DQN: "99AA999",
    Pasportnömrəsi: "AA3282829",
    Status: "-",
    Qeyd: "-",
  },
  {
    Tarix: "10.02.2024",
    Nömrə: "00000007",
    Müəssisə: "Azterminal...",
    Müştəritipi: "rezident",
    VÖEN: "14015550...",
    Müştəri: "Siesco MMC",
    Müştərikodu: "1278383996",
    Strukturbölmə: "Qırmızı körp...",
    Məsulşəxs: "Xanmem...",
    DQN: "99AA999",
    Pasportnömrəsi: "AA3282829",
    Status: "-",
    Qeyd: "-",
  },
  {
    Tarix: "10.02.2024",
    Nömrə: "00000008",
    Müəssisə: "Azterminal...",
    Müştəritipi: "rezident",
    VÖEN: "14015550...",
    Müştəri: "Siesco MMC",
    Müştərikodu: "1278383996",
    Strukturbölmə: "Astara GP",
    Məsulşəxs: "Nurlan Şü...",
    DQN: "99AA999",
    Pasportnömrəsi: "AA3282829",
    Status: "-",
    Qeyd: "-",
  },
  {
    Tarix: "10.02.2024",
    Nömrə: "00000009",
    Müəssisə: "Azterminal...",
    Müştəritipi: "rezident",
    VÖEN: "14015550...",
    Müştəri: "Siesco MMC",
    Müştərikodu: "1278383996",
    Strukturbölmə: "Qırmızı körp...",
    Məsulşəxs: "Xanmem...",
    DQN: "99AA999",
    Pasportnömrəsi: "AA3282829",
    Status: "-",
    Qeyd: "-",
  },
  {
    Tarix: "10.02.2024",
    Nömrə: "000000010",
    Müəssisə: "Azterminal...",
    Müştəritipi: "rezident",
    VÖEN: "14015550...",
    Müştəri: "Siesco MMC",
    Müştərikodu: "1278383996",
    Strukturbölmə: "Astara GP",
    Məsulşəxs: "Nurlan Şü...",
    DQN: "99AA999",
    Pasportnömrəsi: "AA3282829",
    Status: "-",
    Qeyd: "-",
  },
  {
    Tarix: "10.02.2024",
    Nömrə: "000000011",
    Müəssisə: "Azterminal...",
    Müştəritipi: "rezident",
    VÖEN: "14015550...",
    Müştəri: "Siesco MMC",
    Müştərikodu: "1278383996",
    Strukturbölmə: "Qırmızı körp...",
    Məsulşəxs: "Xanmem...",
    DQN: "99AA999",
    Pasportnömrəsi: "AA3282829",
    Status: "-",
    Qeyd: "-",
  },
  {
    Tarix: "10.02.2024",
    Nömrə: "000000012",
    Müəssisə: "Azterminal...",
    Müştəritipi: "rezident",
    VÖEN: "14015550...",
    Müştəri: "Siesco MMC",
    Müştərikodu: "1278383996",
    Strukturbölmə: "Astara GP",
    Məsulşəxs: "Nurlan Şü...",
    DQN: "99AA999",
    Pasportnömrəsi: "AA3282829",
    Status: "-",
    Qeyd: "-",
  },
  {
    Tarix: "10.02.2024",
    Nömrə: "000000013",
    Müəssisə: "Azterminal...",
    Müştəritipi: "rezident",
    VÖEN: "14015550...",
    Müştəri: "Siesco MMC",
    Müştərikodu: "1278383996",
    Strukturbölmə: "Qırmızı körp...",
    Məsulşəxs: "Xanmem...",
    DQN: "99AA999",
    Pasportnömrəsi: "AA3282829",
    Status: "-",
    Qeyd: "-",
  },
  {
    Tarix: "10.02.2024",
    Nömrə: "000000014",
    Müəssisə: "Azterminal...",
    Müştəritipi: "rezident",
    VÖEN: "14015550...",
    Müştəri: "Siesco MMC",
    Müştərikodu: "1278383996",
    Strukturbölmə: "Astara GP",
    Məsulşəxs: "Nurlan Şü...",
    DQN: "99AA999",
    Pasportnömrəsi: "AA3282829",
    Status: "-",
    Qeyd: "-",
  },
  {
    Tarix: "10.02.2024",
    Nömrə: "000000015",
    Müəssisə: "Azterminal...",
    Müştəritipi: "rezident",
    VÖEN: "14015550...",
    Müştəri: "Siesco MMC",
    Müştərikodu: "1278383996",
    Strukturbölmə: "Qırmızı körp...",
    Məsulşəxs: "Xanmem...",
    DQN: "99AA999",
    Pasportnömrəsi: "AA3282829",
    Status: "-",
    Qeyd: "-",
  },
  // Add more entries as needed
];
function Detail() {
  const [isOpen, toggle] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    console.log(id);
  }, []);
  function handlOpenModal(open) {
    toggle(open);
  }
  return (
    <div className={style.container}>
      <div className={style.Head}>
        <div className={style.HeadMain}>
          <div className={style.HeadTitle}>
            <h3>ELEKTRON QAİMƏLƏR ÜZRƏ REYESTR</h3>
          </div>
          <ul className={style.HeadBody}>
            <li>
              <NavLink to={"/EQayime"}>
                <button className={style.HeadExitButton}>
                  <img src="/icons/x-circle 1.svg" alt="" />
                  <span>Çıx</span>
                </button>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
      <div className={style.Body}>
        <div className={style.BodyHead}>
          <ul className={style.InfoItems}>
            <li className={style.FormItem}>
              <label>test</label>
              <span>asdasd</span>
            </li>
            <li className={style.FormItem}>
              <label>test</label>
              <span>asdasd</span>
            </li>
            <li className={style.FormItem}>
              <label>test</label>
              <span>asdasd</span>
            </li>
            <li className={style.FormItem}>
              <label>test</label>
              <span>asdasd</span>
            </li>

            <li className={style.FormItem}>
              <label>test</label>
              <span>asdasd</span>
            </li>
            <li className={style.FormItem}>
              <label>test</label>
              <span>asdasd</span>
            </li>
            <li className={style.FormItem}>
              <label>test</label>
              <span>asdasd</span>
            </li>
            <li className={style.FormItem}>
              <label>test</label>
              <span>asdasd</span>
            </li>
            <li className={style.FormItem}>
              <label>test</label>
              <span>asdasd</span>
            </li>
            <li className={style.FormItem}>
              <label>test</label>
              <span>asdasd</span>
            </li>
            <li className={style.FormItem}>
              <label>test</label>
              <span>asdasd</span>
            </li>
            <li className={style.FormItem}>
              <label>test</label>
              <span>asdasd</span>
            </li>
          </ul>
        </div>
        <div className={style.BodyInfiForm}>
          <ul className={style.TableHeada}>
            <li>No:</li>
            <li>Xidmətin adı</li>
            <li>Ölçü vahidi</li>
            <li>Unikal kod</li>
            <li>Miqdarı</li>
            <li>Qiyməti</li>
            <li>Məbləği</li>
            <li>ƏDV dərəcəsi</li>
            <li>ƏDV məbləği</li>
            <li>Cəmi məbləğ</li>
          </ul>
          <ul className={style.TableBody}>
            <li>
              <span>Sətir yoxdur</span>
            </li>
            <li>
              <span>Sətir yoxdur</span>
            </li>
          </ul>
        </div>
        <div className={style.BodyUserNot}>
          <div className={style.FormItem}>
            <label>Qeyd</label>
            <input
              style={{
                width: "100% !important",
                background: "#F6F6F6",
                padding: "10px 15px",
              }}
              type=""
              placeholder="Qeyd"
            />
          </div>
          <div className={style.NoteUserInfo}>
            <label>Struktur bölmə</label>
            <span>Astara</span>
          </div>
          <div className={style.NoteUserInfo}>
            <ModalUSer
              isOpen={isOpen}
              handleClose={() => handlOpenModal(false)}
            >
              <ModalContent>
                {
                  <ul className={style.UserInfos}>
                    <li className={style.UserInfo}>
                      <label>Məsul şəxsin adı:</label>
                      <span>
                        Fərəczadə Şahin (inspektor){" "}
                        <img src="/icons/info.svg" alt="" />
                      </span>
                    </li>
                    <li className={style.UserInfo}>
                      <label>E-mail:</label>
                      <span>
                        faraczadeshahin@gmail.com
                        <img src="/icons/info.svg" alt="" />
                      </span>
                    </li>
                    <li className={style.UserInfo}>
                      <label>Məntəqənin adı:</label>
                      <span>
                        Siesco MMC
                        <img src="/icons/info.svg" alt="" />
                      </span>
                    </li>
                  </ul>
                }
              </ModalContent>
            </ModalUSer>
            <label>Məsul şəxs</label>
            <span
              onClick={e => {
                handlOpenModal(true);
              }}
            >
              Fərəczadə Şahin (inspektor) <img src="/icons/info.svg" alt="" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Detail;
