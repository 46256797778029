import React, { useState, useEffect } from "react";

const ScreenSizeWatcher = ({ children }) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1366);

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth < 1366);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {isSmallScreen ? (
        <>
          <h1
            style={{
              justifyContent: "center",
              color: "red",
              display: "flex",
              alignItems: "center",
              marginTop: "20%",
            }}
          >
            Cihazınız layihəni dəstəkləmir
          </h1>
          {children(isSmallScreen)}
        </>
      ) : (
        children(isSmallScreen)
      )}
    </>
  );
};

export default ScreenSizeWatcher;
