const url = "http://164.68.98.18:82/api/";
class AuthService {
  static async CheckToken(token) {
    const response = await fetch(`${url}auth/CheckToken/${token}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "text/plain",
      },
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message);
    }

    return response.json();
  }
  static async changePassword(token, password) {
    const response = await fetch(`${url}Auth/ChangePassword/${token}`, {
      method: "POST",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json-patch+json",
      },
      body: JSON.stringify(password),
    });
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message);
    }

    return response.json();
  }
  static async sendPassword(email) {
    console.log(email);
    const response = await fetch(`${url}auth/ResetPassword/${email}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "text/plain",
      },
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message);
    }

    return response.json();
  }
  static async login(username, password) {
    console.log(username);
    console.log(password);
    try {
      const response = await fetch(`${url}Auth/Login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });
      console.log(response);
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message);
      }

      return response.json();
    } catch (error) {
      throw new Error("Login failed");
    }
  }
}

export default AuthService;
